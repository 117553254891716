import React from "react"
import Layout from "../components/layout"
import Oppettider from "../components/oppettider"

export default function OppettiderPage() {
  return (
    <Layout>
      <Oppettider />
    </Layout>
  )
}
